import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { navigate } from 'gatsby';
import { TWork } from '../../../pages/works';

const WorkBox = styled.div(() => [tw``]);

const ImageContainer = styled.img(() => [
  tw`rounded-[10px] mb-6 w-full h-[333px] cursor-pointer object-cover overflow-hidden hover:opacity-80`
]);

const ValueTitle = styled.h4(() => [tw`font-semibold text-xl text-orange text-left mt-0 cursor-pointer mb-0`]);

const LowerValue = styled.span(() => [tw`font-normal text-spaceGray cursor-pointer`]);

interface Props {
  className?: string;
  data: TWork;
}

const WorkCard: React.FC<Props> = ({ className, data }) => {

  return (
    <WorkBox className={className}>
      <ImageContainer onClick={() => navigate(`/work/${data.node.Slug}`)} src={data.node.CardImage.localFile.publicURL} />
      <ValueTitle onClick={() => navigate(`/work/${data.node.Slug}`)}>
        {data.node.Title}
      </ValueTitle>
      <LowerValue onClick={() => navigate(`/work/${data.node.Slug}`)}>{data.node.Category}</LowerValue>
    </WorkBox>
  );
};

export default WorkCard;
