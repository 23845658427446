import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Common/Page';
import OurWorkSection from '../components/pages/WorkPage/OurWorkSection';
import IndexLayout from '../layouts';
import OurClientSection from '../components/pages/IndexPage/OurClientSection';

export interface TWork {
  node: {
    id: string;
    strapiId: number;
    Category: string;
    Title: string;
    Client: string;
    Slug: string;
    CardImage: {
      localFile: {
        publicURL: string;
      };
    };
  };
}

const WorkPage: React.FC<any> = ({ data }) => {
  return (
    <IndexLayout transparent>
      <Page>
        <OurWorkSection data={data.allStrapiOurWorks.edges as TWork[]} />
        <OurClientSection />
      </Page>
    </IndexLayout>
  );
};

export default WorkPage;

export const query = graphql`
  query WorkOurWorkSectionQuery {
    allStrapiOurWorks {
      edges {
        node {
          CardImage {
            localFile {
              publicURL
            }
          }
          Client
          Slug
          Category
          Title
          id
          strapiId
        }
      }
    }
  }
`;
