import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { TWork } from '../../../pages/works';
import { PageHeaderText } from '../../../utils/TextLabel';
import { Button, ButtonType } from '../../Common/Button';
import WorkCard from './WorkCard';

const StyledContainer = styled.section(() => [
  tw`relative z-10 md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto pt-10 pb-20 md:pt-20 sm:mb-20 md:mb-14 xl:mb-0 flex flex-col`
]);

const Title = styled.h2(() => [tw`flex w-full text-[32px] text-left font-semibold text-spaceGray mt-0 mb-8 sm:mb-12`]);

const WorkContainer = styled.div(() => [
  tw`grid grid-cols-1 md:grid-cols-2 max-w-full gap-16 gap-y-4 md:gap-16 bg-transparent`
]);

export const Background = styled.div(() => [tw`absolute top-0 h-[785px] w-screen background-color[#E46B25] `]);

interface OurWorkSectionProps {
  className?: string;
  data: TWork[];
}

const OurWorkSection: React.FC<OurWorkSectionProps> = ({ className, data }) => {
  const [limitWorks, setLimitWorks] = useState(4);
  const [worksToShow, setWorksToShow] = useState<TWork[]>([]);

  useEffect(() => {
    const toShow = data
      .filter((item, index) => index + 1 <= limitWorks && item);
    setWorksToShow(toShow);
  }, [data, limitWorks]);

  const works = worksToShow;

  return (
    <StyledContainer className={className}>
      <PageHeaderText>Our works</PageHeaderText>
      <WorkContainer>
        {works &&
          works.map((work) => (
            <WorkCard
              key={work.node.Slug}
              data={work}
            />
          ))}
      </WorkContainer>
      {limitWorks < data.length && (
        <Button
          type={ButtonType.PRIMARY}
          className="mx-auto mt-18 px-6"
          onClick={() => setLimitWorks(limitWorks + 4)}
        >
          Show more
        </Button>
      )}
    </StyledContainer>
  );
};

export default OurWorkSection;
